.request-main {
    text-align: center;
}
.request-main .request-form-parent {
    padding : 50px;
    border : 1px solid #5E3FE3;
    border-radius : 10px;
    width: 60%;
    margin : 5% auto;

}
.request-main .request-form-parent .request-title {

}
.request-main .request-form-parent form.request-form {
    display: flex;
    justify-content: space-between;
    flex-wrap : wrap;
    margin-top : 7%;
}
.request-main .request-form-parent form.request-form .request-input {
    width : 48%;
    height : 60px;
    padding : 20px;
    margin-bottom : 30px;
    border : 0.5px solid #b1b1b1;
    border-radius : 10px;   
}
.request-main .request-form-parent form.request-form .request-submit {
    width : 100%;
    height : 60px;
    background-color: #5E3FE3;
    border : none;
    border-radius : 10px;
    color : #fff;
}
.request-main .request-last {

}