.city-composition-parent {
    display: flex;
    justify-content: center;
    align-items: center;
    padding : 30px;
}

.piechart {
    height : 500px !important;
    width : 500px !important;
}